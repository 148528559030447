.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  margin-top: 10px;
}

.canvas {
  position: relative;
  max-width: 875px;
  width: 100%;
  height: auto;
  margin: 0px auto;
  padding-left: 10px;
  padding-right: 10px;
  white-space: pre-wrap;
  text-align: justify;
}

.paper {
  position: relative;
  width: 875px !important;
  height: auto;
  margin: 0px auto;
  border-radius: 10px;
  background-color: rgb(250, 250, 250);
  box-shadow: rgb(0 0 0 / 30%) 0px 2px 8px;
  padding-left: 70px;
  padding-right: 10px;
  white-space: pre-wrap;
  text-align: justify;
}

.paper p {
  text-indent: 25px;
  margin-block-end: 0px;
  margin-block-start: 0px;
}

.paperBg {
  background: linear-gradient(
      transparent,
      transparent 28px,
      rgb(145, 209, 211) 28px
    )
    0% 0% / 30px 30px;
  padding-right: 20px;
  line-height: 30px;
  font-size: 1em;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  margin-left: 70px;
  min-height: 905px;
}

.paragraph {
  padding: 0px 16px 0px 64px;
}

.line {
  height: 30px;
  color: rgb(204, 204, 204);
  font-size: 1.25rem;
  text-align: right;
}

.lineCount {
  position: absolute;
  top: 0px;
  left: 32px;
  flex-direction: column;
  display: flex;
}

.error {
  position: relative;
  width: 100%;
  text-align: left;
  line-height: 30px;
  font-size: 1rem;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  border: 0px solid transparent;
  background: transparent;
  outline: none;
  resize: none;
}

.paper::before {
  content: "";
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 60px;
  box-sizing: border-box;
  background: radial-gradient(rgb(87, 84, 80) 6px, transparent 7px) 0% 0% / 30px
    30px repeat-y;
  border-right: 3px solid rgb(212, 65, 71);
}

.lDVfN span {
  position: relative;
  color: rgb(255, 255, 255);
}

span {
  font-style: inherit;
  font-weight: inherit;
}

.nonSelected {
  padding: 7px 13px;
  border: 1px solid #55555550;
  border-radius: 5px;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
}

.nonSelected:hover {
  border: 1px solid #4495c1;
  transition: ease-in-out 0.3s;
  animation: normal 0.3s;
}

.selected {
  padding: 7px 13px;
  border: 1px solid #4495c1;
  border-radius: 5px;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  cursor: pointer;
  color: #4495c1;
}
